import ScrollToTop from "react-scroll-to-top";
import TitleBox from './components/TitleBox';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import ImageSubsection from './components/ImageSubsection';
import TopNav from '../../toolbar/TopNav';
import ReactCodeInput from 'react-code-input';
import { useState } from "react";
import image_11JobsOverview from '../../images/riceai/1.1_Jobs Overview.png';
import image_12YieldMonitor from '../../images/riceai/1.2_Yield Monitor.png';
import image_15SortexYield from '../../images/riceai/1.5_Sortex Yield Analysis_Raw vs Final.png';
import image_18BrokenYield from '../../images/riceai/1.8_Broken Yield Analysis_Raw vs Final.png';
import image_20190823_134215 from '../../images/riceai/20190823_134215.jpg';
import image_20190823_150213 from '../../images/riceai/20190823_150213.jpg';
import image_20190823_151506 from '../../images/riceai/20190823_151506.jpg';
import image_20190823_151523 from '../../images/riceai/20190823_151523.jpg';
import image_21ProductionReport from '../../images/riceai/2.1_production report@2x.png';
import image_22YieldInsights from '../../images/riceai/2.2_yield insights_head rice n pula@2x.png';
import image_23YieldInsights from '../../images/riceai/2.3_yield insights_Sortex Rejects@2x.png';
import image_24YieldInsights from '../../images/riceai/2.4_yield insights_broken rice yield@2x.png';
import image_25YieldInsights from '../../images/riceai/2.5_yield insights_sort efficiency@2x.png';
import image_26YieldInsights from '../../images/riceai/2.6_yield insights_raw vs final defect@2x.png';
import image_27YieldInsights from '../../images/riceai/2.7_insights@2x.png';
import image_CardSelected from '../../images/riceai/Card selected@2x.png';
import image_InsightsCardDesign from '../../images/riceai/Insights Card Design 3@2x.png';
import image_RawVsFinalTable from '../../images/riceai/Raw vs Final table for each recipe@2x.png';
import image_RawVsFinalSpiderweb from '../../images/riceai/Raw vs final spiderweb@2x.png';
import image_YieldAnalysis from '../../images/riceai/Yield Analysis@2x.png';
import video_InteractionVideo from '../../images/riceai/Rice.ai_Interaction video_it1.mp4';
import Footer from "../../toolbar/Footer";
import { useWindowSize } from "@uidotdev/usehooks";
import './RiceAI.css';


const RiceAI = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('authorized'));
    const size = useWindowSize();

    const validateCode = (code) => {
        const SUPER_SAFE_ACCESS_CODE = '314159';
        if (code === SUPER_SAFE_ACCESS_CODE) {
            setIsLoggedIn(true);
            localStorage.setItem('authorized', true);
        }
    };

    return (
        <div className="mainBox">
            {!isLoggedIn &&
                <div className="passwordNeeded" style={{height: size.height}}>
                    <div>This work is confidential, and requires an access code.</div>
                    <ReactCodeInput type='text' fields={6} onChange={validateCode}/>
                    <div><a href="mailto:shivani.visen@gmail.com?subject=Request%20access%20code">Request code</a></div>
                </div>
            }
            <TopNav activeIndex={-1} noBackground={true}/>
            <div className="riceAi project">
                {isLoggedIn &&
                    <div className="project-inner">
                        <TitleBox
                            title="Rice AI <span class='cursive'>- Lead Designer</span>"
                            description='Rice AI is an app designed for the rice processing plant workforce. I designed this product for a customer who is a leader in the food industry. More than 60% of rice produced worldwide is processed using their optical sorting machine, <a href="https://www.buhlergroup.com/global/fr/products/sortex_s_ultravisionopticalsorter.html" target="_blank" rel="noreferrer">Sortex</a>. The app analyzes the raw and production data gathered from the machines and generates insights to reduce rice wastage while increasing the quantity of high-quality rice.'
                        />
                        <ImageSubsection
                            title=""
                            images={[
                                { image: image_20190823_134215, small: false },
                            ]}
                            description=""
                        />
                        <ProblemStatement
                            title="Problem Statement"
                            description="The Rice AI app should analyze the rice sorting machine data, pre-and post-processing, and suggest machine sensitivity calibration to the vendors using a user interface to reduce rice wastage. The vendors should also be able to get a historical overview of overall processing data and compare it with the raw data to focus on the factors leading to rice wastage, also in monetary terms."
                        />
                        <ProblemStatement
                            title="Users"
                            description="The app is primarily meant for customers of rice sorting machine manufacturers. These customers vary from large processing plants with a large workforce of machine operators to small millers."
                        />
                        <ImageSubsection
                            title="Research"
                            images={[
                                { image: image_20190823_151523, small: false },
                                { image: image_20190823_150213, small: false },
                            ]}
                            description="The research phase focused on three aspects: understanding the user needs, understanding the machine data, and familiarizing myself with the terminologies and concepts used in the rice industry. I conducted many user interviews to understand how the users analyze data. I visited the rice processing plants to see the sorting machine in action and observe a complete rice sorting cycle. I also compiled essential terms and their meanings so our team could understand and speak the same language as our customers."
                        />
                        <ImageSubsection
                            title="The Sorting Machine"
                            images={[
                                { image: image_20190823_151506, small: false },
                            ]}
                            description="The SORTEX is an optical sorter for rice for raw, parboiled, and steam varieties. It can remove color defects and foreign materials. Grains fall by gravity during sorting. Machine sensors have light and camera. It ejects an air puff to strike that grain out whenever it spots a shape or color defect. In the end, there are two outputs from two slots: good yield and defects. The machine's sensitivity can be adjusted for different defects, and the output can be measured."
                        />
                        <Solution
                            smallTitle={true}
                            title="Below is a small collection of valuable terms and notes to help you understand the project. "
                            description={[
                                '24 tons of rice is sorted per hour ',
                                'It removes color defects and foreign objects like wood, glass, plastic, etc. ',
                                'Low sorting is not good as it means that the quality is poor. ',
                                'High sorting is not good as it means that quantity is low. ',
                                'Recipe = Rice type',
                                'Raw = Raw quality control ',
                                'Final = Production quality control ',
                                'Job = A single job run of the sorting machine',
                                'A single job processes a single recipe, but a single recipe can have many jobs. One year of data can have around 200 jobs. ',
                                'Head Rice = Whole complete grain',
                                'Pula Rice = Slightly broken grain. Head Rice and Pula Rice are counted together as a good yield.',
                                'Raw can consist of Broken and Waste. ',
                                '"Broken" consists of Broken (real broken) + defect (Sortex reject). Broken has a 5% tolerance limit; therefore, 5 % (+/-) broken is acceptable in raw data.',
                            ]}
                        />
                        <ImageSubsection
                            title="Design Strategy"
                            images={[
                                { image: image_InsightsCardDesign, small: false },
                                { image: image_CardSelected, small: false },
                                { image: image_27YieldInsights, small: false },
                            ]}
                            description="During user interviews, I understood that wastage is measured in kgs, while capital loss is measured in $. The most impactful way of understanding wastage would be to combine these two units and show rice wastage data in kg / $. I came up with an insights dashboard with system-generated notification cards. It is generated in real-time whenever it detects the yield or the quality goes low. It also computes the wastage value in dollars. Clicking on the card details related information using various charts and tables. It is the most crucial dashboard in the application as it is the quickest way to notify millers about losses and prompt them to recalibrate the machine."
                        />
                        <ImageSubsection
                            title=""
                            images={[
                                { image: image_11JobsOverview, small: false },
                                { image: image_12YieldMonitor, small: false },
                                { image: image_15SortexYield, small: false },
                                { image: image_18BrokenYield, small: false },
                                { image: image_YieldAnalysis, small: false },
                                { image: image_RawVsFinalSpiderweb, small: false },
                                { image: image_RawVsFinalTable, small: false },
                            ]}
                            description="In the conceptualization stage, I brainstormed on different charts and visualizations to tell a comprehensive story in a user-friendly UI. I tested the charts with the end-users and narrowed them down to the most voted charts, like spider charts for comparison between raw and final, bar charts, pie charts, and data tables."
                        />
                        <ImageSubsection
                            title=""
                            images={[
                                { image: image_21ProductionReport, small: false },
                                { image: image_22YieldInsights, small: false },
                                { image: image_23YieldInsights, small: false },
                                { image: image_24YieldInsights, small: false },
                                { image: image_25YieldInsights, small: false },
                                { image: image_26YieldInsights, small: false },
                            ]}
                            description="Based on the user interviews, I identified the need for additional dashboards. The Production report dashboard gives an overview of all jobs, all recipes, and the produce type. The yield Analysis dashboard focuses on different kinds of yields like head rice, sortex rejects, sort efficiency, and raw vs final defect."
                        />
                        <Solution
                            title="Design Details"
                            description={[
                                'After many iterations, the final version was built on Figma using Google Material Design components.',
                                'To avoid the risk of overwhelming the users with too many charts, the dashboards focus on three types of information: data over time, data by recipe, and data by yield type to condition users to internalize this structure when analyzing data.',
                                'Spider charts, in particular, were a hit as they highlighted both quality and quantity progression and regression for several data points tracked along the central axis of the chart.',
                                'Dashboards have data tables below the charts so users can apply filters and view the filtered-down table below.',
                            ]}
                        />
                        <ImageSubsection
                            title=""
                            images={[
                                { video: video_InteractionVideo, small: true },
                            ]}
                            description=""
                        />
                    </div>
                }
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
};

export default RiceAI;
